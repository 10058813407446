import React from "react"
import { LinkedinShareButton } from "react-share"
import LinkedIn from "@svg/social/linkedin.svg"

export const Share = ({ title }) => {
  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <ul className="flex items-center ml-0">
      <li className="inline-block pl-0">
        <LinkedinShareButton url={url} className="border border-blue">
          <div className="block transition-all duration-300 fill-current text-gray-darkest sm:hover:text-teal w-25px">
            <LinkedIn />
          </div>
        </LinkedinShareButton>
      </li>
    </ul>
  )
}
